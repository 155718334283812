import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateJobArgs } from '../../generated/types';

export const UPDATE_JOB = gql`
  mutation UpdateJob($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $data: JobInputData!) {
    updateJob(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data) {
      uuid

      status
      feeling

      isThroughAgency
      agencyName
      agentName
      agentEmail
      agentPhone
      referralFee

      company
      companyInfo
      companyLinkedIn
      companyAvatar
      companyWebsite
      companyLocation
      companyLocationCity
      companyLocationCountry
      companyLocationLatitude
      companyLocationLongitude
      companyLocationPostCode
      companyLocationStreet
      companyLocationUrl
      companyDistances

      jobTitle
      jobSeniorityLevel
      jobDescription
      jobRequirement
      jobUrl

      ir35
      duration
      rate
      employmentType
      remoteOption

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
        temporaryImageUrl
      }

      events {
        uuid
        type
        startAt
        endAt
        isDeleted
        createdAt
        updatedAt
        createdBy {
          uuid
          email
          nickname
          name
        }
        updatedBy {
          uuid
          email
          nickname
          name
        }
      }

      contacts {
        uuid
        fullName
        position
        email
        phoneNumber
        linkedin
      }

      isOwner
      owner {
        uuid
        email
        nickname
        name
      }

      createdBy {
        uuid
        email
        nickname
        name
      }

      updatedBy {
        uuid
        email
        nickname
        name
      }

      createdAt
      updatedAt
      appliedAt
      interviewedAt
      offerAt
      rejectedAt

      followers {
        uuid
        email
        nickname
        name
        avatar
      }

      aiIsProcessed

      permissions

      isDeleted
    }
  }
`;

export const useUpdateJob = (options?: MutationHookOptions<Pick<Mutation, 'updateJob'>, MutationUpdateJobArgs>) =>
  useMutation<Pick<Mutation, 'updateJob'>, MutationUpdateJobArgs>(UPDATE_JOB, {
    awaitRefetchQueries: false,
    refetchQueries: [
      // {
      //   query: GET_BOARDS,
      //   variables: { where: { isDeleted: false }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
      // },
      // {
      //   query: GET_BOARDS,
      //   variables: { where: { isDeleted: true }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
      // },
    ],
    // onCompleted: (data) => {
    //   console.log('onCompleted useUpdateBoard', data);
    // },
    ...options,
  });
